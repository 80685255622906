import React from 'react';
import { BadgePositionEnum, IProductBadge } from '@components/ProductBadge/types';
import clsx from 'clsx';
import { getStyledObjectFromString } from '@utils/getStyledObjectFromString';
import styles from './styles/productBadge.module.scss';

const ProductBadge = ({ style, position, text, isCategory }: IProductBadge) => {
    if (!text) return null;

    const getPositionClassName = () => {
        switch (position) {
            case BadgePositionEnum.TOP_RIGHT:
                return [styles.top, styles.right];
            case BadgePositionEnum.BOTTOM_LEFT:
                return [styles.bottom, styles.left];
            case BadgePositionEnum.BOTTOM_RIGHT:
                return [styles.bottom, styles.right];
            case BadgePositionEnum.TOP_LEFT:
            default:
                return [styles.top, styles.left];
        }
    };

    return (
        <div
            className={clsx(styles.root, getPositionClassName(), isCategory && styles.badgeCategory)}
            style={getStyledObjectFromString(style)}
        >
            {text}
        </div>
    );
};

export default ProductBadge;
