export enum BadgePositionEnum {
    TOP_LEFT,
    TOP_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_RIGHT,
}

export interface IProductBadge {
    text: string;
    position?: BadgePositionEnum;
    style?: string;
    isCategory?: boolean;
}
