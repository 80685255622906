import { CSSProperties } from 'react';

const formatStringToCamelCase = (str: string) => {
    const splittedString = str.split('-');
    if (splittedString.length === 1) return splittedString[0];
    return (
        splittedString[0] +
        splittedString
            .slice(1)
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join('')
    );
};

export const getStyledObjectFromString = (providedString?: string): CSSProperties | undefined => {
    if (!providedString) return;

    const style = {};
    providedString.split(';').forEach((el) => {
        const [property, value] = el.split(':');
        if (!property) return;

        const formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
    });

    return style;
};
